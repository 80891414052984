<template>
  <div>
    <div class="business_bg">
      <img src="../../static/image/mobile/m_business_bg.png" />
    </div>
    <van-list
      class="business_list"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getList"
    >
      <div
        class="business_list_item"
        @click="toDetails(item)"
        v-for="item in businessList"
        :key="item.id"
      >
        <img
          v-if="item.thumbnail"
          :src="'https://admin.zoneyu.cn/' + item.thumbnail"
          width="170px"
        />
        <div class="business_list_item_text">{{ item.title }}</div>
      </div>
    </van-list>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "business",
  data() {
    return {
      businessList: [],
      loading: false,
      finished: false,
      query: {
        page: 0,
        size: 8,
        total: 0,
      },
    };
  },
  mounted() {
    window.scroll(0, 0);
  },
  methods: {
    toDetails(item) {
      this.$router.push({ path: "/mbusinessDetail", query: { id: item.id } });
    },
    getList() {
      axios
        .get(
          `https://admin.zoneyu.cn/api/admin/posts?status=PUBLISHED&page=${
            this.query.page
          }&size=8&categoryId=16`, // 16
          {
            headers: {
              "Admin-Authorization": localStorage.getItem("token"),
            },
          }
        )
        .then(async (res) => {
          this.query.page += 1;
          const content = res.data.data.content;
          content.forEach((item) => {
            const updateTime = new Date(item.createTime);
            item.day =
              updateTime.getDate() > 9
                ? updateTime.getDate()
                : "0" + updateTime.getDate();
            item.year =
              updateTime.getFullYear() +
              "." +
              (updateTime.getMonth() + 1 > 9
                ? updateTime.getMonth() + 1
                : "0" + (updateTime.getMonth() + 1));
            item.title =
              item.title.length > 30
                ? item.title.substring(0, 30) + "..."
                : item.title;
          });
          this.businessList = this.businessList.concat(content);
          this.query.total = res.data.data.total;
          this.loading = false;
          if ((this.query.page + 1) * 8 > res.data.data.total) {
            this.finished = true
          }
        });
    },
    handleCurrentChange(val) {
      this.query.page = val - 1;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.business_bg {
  img {
    width: 100%;
  }
}
.business_list {
  padding: 15px 12px;
  width: calc(100% - 24px);
  .business_list_item {
    cursor: pointer;
    margin-bottom: 20px;
    display: inline-block;
    .business_list_item_text {
      font-size: 12px;
      font-weight: bold;
      color: #1d1b19;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .business_list_item_time {
      margin-top: 20px;
      width: 170px;
      height: 28px;
      background: #efefef;
      font-size: 14px;
      font-weight: 400;
      color: #abaeb0;
      line-height: 28px;
      text-align: center;
    }
  }
  .business_list_item:nth-child(2n + 1) {
    margin-right: 10px;
  }
}
</style>
